<template>
  <div>
    <v-row
      justify="left"
      align="center"
      no-gutters
      class="flex-nowrap"
    >
      <vue-date-picker
        v-model="dateRange"
        type="year"
        :clearable="false"
        range
      ></vue-date-picker>
    </v-row>
  </div>
</template>

<script>
import VueDatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import { ref } from '@vue/composition-api'

export default {
  components: { VueDatePicker },
  setup() {
    const dateRange = ref([])
    dateRange.value = [new Date(), new Date()]

    return {
      dateRange,
    }
  },
}
</script>
