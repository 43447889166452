<template>
  <div>
    <Loading v-show="loading"></Loading>
    <v-form
      ref="form"
      class="multi-col-validation"
    >
      <v-radio-group v-model="deliveryOption">
        <div
          class="delivery-options"
          :class="deliveryOption === 'dailySummary' ? 'active':''"
        >
          <div class="d-flex justify-space-between">
            <v-radio value="dailySummary">
              <template #label>
                <span class="text--primary text-lg font-weight-semibold">Daily Summary</span>
              </template>
            </v-radio>
          </div>
          <div class="ms-8">
             <v-row>
              <v-col cols="4">
                <date-range-picker
                ref="dailyPicker"
                :search-btn-show="false"
                :shortcuts-show="false"
              ></date-range-picker>
              </v-col>
              <v-col style="padding-left: 9.5%;">
                <v-btn
                  style="text-transform: none"
                  color="primary"
                  @click="search"
                >
                  Search
                </v-btn>
              </v-col>
             </v-row>
          </div>
        </div>
        <div
          class="delivery-options"
          :class="deliveryOption === 'monthlySummary' ? 'active':''"
        >
          <div class="d-flex justify-space-between">
            <v-radio value="monthlySummary">
              <template #label>
                <span class="text--primary text-lg font-weight-semibold"> Monthly Summary</span>
              </template>
            </v-radio>
          </div>
          <div class="ms-8">
             <v-row>
              <v-col cols="4">
                 <month-range-picker
                  ref="monthPicker"
                  ></month-range-picker>
              </v-col>
              <v-col style="padding-left: 9.5%;">
                <v-btn
                  style="text-transform: none"
                  color="primary"
                  @click="search"
                >
                  Search
                </v-btn>
              </v-col>
             </v-row>
          </div>
        </div>
        <div
          class="delivery-options"
          :class="deliveryOption === 'yearlySummary' ? 'active':''"
        >
          <div class="d-flex justify-space-between">
            <v-radio value="yearlySummary">
              <template #label>
                <span class="text--primary text-lg font-weight-semibold">Yearly Summary</span>
              </template>
            </v-radio>
          </div>
          <div class="ms-8">
            <v-row>
              <v-col cols="4">
                <div class="demo-space-x d-flex flex-wrap">
                <year-range-picker
                  ref="yearPicker"
                  :search-btn-show="false"
                  :shortcuts-show="false"
                ></year-range-picker>
            </div>
              </v-col>
              <v-col style="padding-left: 9.5%;">
                <v-btn
                  style="text-transform: none"
                  color="primary"
                  @click="search"
                >
                  Search
                </v-btn>
              </v-col>
            </v-row>
          </div>
        </div>
        <div
          class="delivery-options"
          :class="deliveryOption === 'summaryDatabyHour' ? 'active':''"
        >
          <div class="d-flex justify-space-between">
            <v-radio value="summaryDatabyHour">
              <template #label>
                <span class="text--primary text-lg font-weight-semibold"> Summary Data by Hour</span>
              </template>
            </v-radio>
          </div>
          <div class="ms-8">
            <v-row>
              <v-col cols="4">
                <date-range-picker
              ref="summaryDataPicker"
              :search-btn-show="false"
              :shortcuts-show="false"
            ></date-range-picker>
              </v-col>
              <v-col style="padding-left: 9.5%;">
                <v-btn
                  style="text-transform: none"
                  color="primary"
                  @click="search"
                >
                  Search
                </v-btn>
              </v-col>
            </v-row>
          </div>
        </div>
        <div
          class="delivery-options"
          :class="deliveryOption === 'comparisonSummaryDatabyHour' ? 'active':''"
        >
          <div class="d-flex justify-space-between">
            <v-radio value="comparisonSummaryDatabyHour">
              <template #label>
                <span class="text--primary text-lg font-weight-semibold"> Comparison Summary Data by Hour</span>
              </template>
            </v-radio>
          </div>
          <div class="ms-8">
            <v-row>
              <v-col
                style="flex: 0 0 13%; max-width: 13%;"
              >
                <v-menu
                  ref="comparisonFromMonthMenuref"
                  v-model="comparisonFromMonthMenu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="computedDateFormattedComparisonFromMonth"
                      :prepend-icon="icons.mdiCalendarMonth"
                      readonly
                      outlined
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="comparisonFromMonth"
                    type="month"
                    color="primary"
                    @input="comparisonFromMonthMenu = false"
                  >
                  </v-date-picker>
                </v-menu>
              </v-col>
              <div style="margin-top: 30px;">
                <label>&</label>
              </div>
              <v-col
                style="flex: 0 0 13%; max-width: 13%;"
              >
                <v-menu
                  ref="comparisonToMonthMenuref"
                  v-model="comparisonToMonthMenu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="computedDateFormattedComparisonToMonth"
                      :prepend-icon="icons.mdiCalendarMonth"
                      readonly
                      outlined
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="comparisonToMonth"
                    date-format="mmm/yyyy"
                    type="month"
                    color="primary"
                    @input="comparisonToMonthMenu = false"
                  >
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col style="padding-left: 16%;">
                <v-btn
                  style="text-transform: none"
                  color="primary"
                  @click="search"
                >
                  Search
                </v-btn>
              </v-col>
            </v-row>
          </div>
        </div>
        <div
          class="delivery-options"
          :class="deliveryOption === 'monthlySummarybyHour' ? 'active':''"
        >
          <div class="d-flex justify-space-between">
            <v-radio value="monthlySummarybyHour">
              <template #label>
                <span class="text--primary text-lg font-weight-semibold">  Monthly Summary by Hour</span>
              </template>
            </v-radio>
          </div>
          <div class="ms-6">
            <v-row>
              <v-col
                style="flex: 0 0 13%; max-width: 13%;"
              >
                <v-menu
                  ref="msbyHourMonthMenuref"
                  v-model="msbyHourMonthMenu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="computedDateFormattedMsbyHourMonth"
                      :prepend-icon="icons.mdiCalendarMonth"
                      readonly
                      outlined
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="msbyHourMonth"
                    date-format="mmm/yyyy"
                    type="month"
                    color="primary"
                    @input="msbyHourMonthMenu = false"
                  >
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col style="padding-left: 30%;">
                <v-btn
                  style="text-transform: none"
                  color="primary"
                  @click="search"
                >
                  Search
                </v-btn>
              </v-col>
            </v-row>
          </div>
        </div>
      </v-radio-group>
    </v-form>
    <div id="chart">
      <br>
      <div
        v-if="!isEmpty(userData.seriesData)"
        ref="lineChart"
        :style="{ width: '100%', height: '400px'}"
      ></div>
      <div
          class="d-flex align-left flex-wrap"
          v-if="!isEmpty(userData.seriesData)"
        >
          <v-spacer></v-spacer>
          <v-switch
            style="width:250px"
            v-model="countDisplayswitch"
            flat
            :label="countDisplayswitch ? `Count Display`:`Count Undisplayed`"
            hide-details
            @change="countDisplay"
          ></v-switch>
      </div>
      <div
        v-if="!isEmpty(userData.pieData)"
        ref="pieChart"
        :style="{ width: '100%', height: '500px'}"
      ></div>
      <br>
    </div>
    <v-col
      v-if="!isEmpty(userData.entranceSearch4Data)"
      cols="12"
      md="4"
    >
      <v-simple-table dense>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-center">
                <div v-if="deliveryOption === 'dailySummary'">
                  Date
                </div>
                <div v-if="deliveryOption === 'monthlySummary'">
                  Month
                </div>
                <div v-if="deliveryOption === 'yearlySummary'">
                  Year
                </div>
                <div v-if="deliveryOption === 'summaryDatabyHour'">
                  Time
                </div>
                <div v-if="deliveryOption === 'comparisonSummaryDatabyHour'">
                  Time
                </div>
              </th>
              <th class="text-center">
                <div v-if="deliveryOption !== 'comparisonSummaryDatabyHour'">
                  Data
                </div>
                <div v-if="deliveryOption === 'comparisonSummaryDatabyHour'">
                  {{ formatStringDate(comparisonFromMonth) }}
                </div>
              </th>
              <th
                v-if="deliveryOption === 'summaryDatabyHour'"
                class="text-center"
              >
                Ratio
              </th>
              <th
                v-if="deliveryOption === 'comparisonSummaryDatabyHour'"
                class="text-center"
              >
                {{ formatStringDate(comparisonToMonth) }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="item in userData.entranceSearch4Data"
              :key="item.time"
            >
              <td class="text-center">
                {{ item.time }}
              </td>
              <td class="text-right">
                {{ String(item.count).replace( /(\d)(?=(\d\d\d)+(?!\d))/g, '$1,') }}
              </td>
              <td
                v-if="deliveryOption === 'summaryDatabyHour'"
                class="text-right"
              >
                {{ item.ratio }}
              </td>
              <td
                v-if="deliveryOption === 'comparisonSummaryDatabyHour'"
                class="text-right"
              >
                {{ String(item.count2).replace( /(\d)(?=(\d\d\d)+(?!\d))/g, '$1,') }}
              </td>
            </tr>
          </tbody>
          <tfoot>
              <tr
                :key="tableFooterData.time"
                class="yellow"
              >
                <td class="text-center"
                >
                  {{ tableFooterData.time }}
                </td>
                <td
                  class="text-right"
                >
                  {{ String(tableFooterData.column1).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,') }}
                </td>
                 <td
                  v-if="deliveryOption === 'summaryDatabyHour'"
                  class="text-right"
                  >
                  100%
                </td>
                <td
                  class="text-right"
                  v-if="deliveryOption === 'comparisonSummaryDatabyHour'"
                >
                  {{ String(tableFooterData.column2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,') }}
                </td>
              </tr>
            </tfoot>
        </template>
      </v-simple-table>
    </v-col>
    <v-col>
      <v-btn
        v-if="!isEmpty(userData.entranceSearch4Data)"
        color="primary"
        dark
        style="text-transform: none"
        @click="generateEntranceSearchXLSX"
      >
        Excel Download
        <v-icon
          dark
          right
        >
          {{ icons.mdiDownloadOutline }}
        </v-icon>
      </v-btn>
    </v-col>
    <div
      v-if="!isEmpty(userData.entranceSearch4Data)"
      id="mainTable"
      hidden
      data-cols-width="20,10"
    >
      <table><tr></tr></table>
      <v-simple-table dense>
        <template v-slot:default>
          <thead>
            <tr>
              <th
                data-a-h="center"
                data-b-a-s="thin"
              >
                <div v-if="deliveryOption === 'dailySummary'">
                  Date
                </div>
                <div v-if="deliveryOption === 'monthlySummary'">
                  Month
                </div>
                <div v-if="deliveryOption === 'yearlySummary'">
                  Year
                </div>
                <div v-if="deliveryOption === 'summaryDatabyHour'">
                  Time
                </div>
                <div v-if="deliveryOption === 'comparisonSummaryDatabyHour'">
                  Time
                </div>
              </th>
              <th
                data-a-h="center"
                data-b-a-s="thin"
              >
                <div v-if="deliveryOption !== 'comparisonSummaryDatabyHour'">
                  Data
                </div>
                <div v-if="deliveryOption === 'comparisonSummaryDatabyHour'">
                  {{ formatStringDate(comparisonFromMonth) }}
                </div>
              </th>
              <th
                v-if="deliveryOption === 'summaryDatabyHour'"
                data-a-h="center"
                data-b-a-s="thin"
              >
                Ratio
              </th>
              <th
                v-if="deliveryOption === 'comparisonSummaryDatabyHour'"
                data-a-h="center"
                data-b-a-s="thin"
              >
                {{ formatStringDate(comparisonToMonth) }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="item in userData.entranceSearch4Data"
              :key="item.time"
            >
              <td
                data-a-h="center"
                data-b-a-s="thin"
              >
                {{ item.time }}
              </td>
              <td
                data-b-a-s="thin"
                data-t="n"
                :data-num-fmt="item.count === 0 ? '0':'#,###'"
              >
                {{ String(item.count).replace(',','') }}
              </td>
              <td
                v-if="deliveryOption === 'summaryDatabyHour'"
                data-b-a-s="thin"
                data-t="n"
                data-num-fmt="0.0%"
              >
                {{ Number(String(item.ratio).replace('%',''))/100 }}
              </td>
              <td
                v-if="deliveryOption === 'comparisonSummaryDatabyHour'"
                data-b-a-s="thin"
                data-t="n"
                :data-num-fmt="item.count2 === 0 ? '0':'#,###'"
              >
                {{ String(item.count2).replace(',','') }}
              </td>
            </tr>
          </tbody>
          <tfoot>
              <tr
                :key="tableFooterData.time"
                class="yellow"
              >
                <td
                data-t="s"
                  data-a-h="center"
                  data-b-a-s="thin"
                  data-fill-color="ffff88"
                >
                  {{ tableFooterData.time }}
                </td>
                <td
                   data-t="n"
                  data-b-a-s="thin"
                  data-fill-color="ffff88"
                  data-num-fmt="###,###"
                >
                  {{tableFooterData.column1 }}
                </td>
                 <td
                  data-t="n"
                  data-b-a-s="thin"
                  data-fill-color="ffff88"
                  v-if="deliveryOption === 'summaryDatabyHour'"
                  data-num-fmt="0.0%"
                  >
                  {{ Number(String(100).replace('%',''))/100 }}
                </td>
                <td
                   data-t="n"
                  data-b-a-s="thin"
                  data-fill-color="ffff88"
                  data-num-fmt="###,###"
                  v-if="deliveryOption === 'comparisonSummaryDatabyHour'"
                >
                  {{tableFooterData.column2 }}
                </td>
              </tr>
            </tfoot>
        </template>
      </v-simple-table>
    </div>

    <lc-monthly-summary-by-hour
      v-if="siteId === '16' && !isEmpty(userData.table1Data)"
      :user-data="userData"
    ></lc-monthly-summary-by-hour>
    <hd-monthly-summary-by-hour
      v-if="siteId === '15' && !isEmpty(userData.table1Data)"
      :user-data="userData"
    ></hd-monthly-summary-by-hour>
    <tp-monthly-summary-by-hour
      v-if="siteId === '12' && !isEmpty(userData.table1Data)"
      :user-data="userData"
    ></tp-monthly-summary-by-hour>
    <bt-monthly-summary-by-hour
      v-if="siteId === '11' && !isEmpty(userData.table1Data)"
      :user-data="userData"
    ></bt-monthly-summary-by-hour>
    <lb-monthly-summary-by-hour
      v-if="siteId === '8' && !isEmpty(userData.table1Data)"
      :user-data="userData"
    ></lb-monthly-summary-by-hour>
    <bd-monthly-summary-by-hour
      v-if="siteId === '3' && !isEmpty(userData.table2Data)"
      :user-data="userData"
    ></bd-monthly-summary-by-hour>
    <tm-monthly-summary-by-hour
      v-if="siteId === '14' && !isEmpty(userData.table1Data)"
      :user-data="userData"
    ></tm-monthly-summary-by-hour>
    <pp-monthly-summary-by-hour
      v-if="siteId === '2' && !isEmpty(userData.table1Data)"
      :user-data="userData"
    ></pp-monthly-summary-by-hour>
  </div>
</template>

<script>
import { ref, onUnmounted } from '@vue/composition-api'
import DateRangePicker from '@/views/forms/form-elements/datepicker/DateRangePicker.vue'
import YearRangePicker from '@/views/forms/form-elements/datepicker/YearRangePicker.vue'
import MonthRangePicker from '@/views/forms/form-elements/datepicker/MonthRangePicker.vue'
import { mdiCalendarMonth, mdiDownloadOutline } from '@mdi/js'
import store from '@/store'
import tableToExcelJs from '@linways/table-to-excel'
import entranceSearch4StoreModule from './entranceSearch4StoreModule'
import { isEmpty, getSessionData, date2String, date2yyyymmddhhmmss } from '@core/utils'
import 'echarts/lib/chart/line'
import Loading from '@/views/components/load/Loading.vue'
import moment from 'moment'
import LcMonthlySummaryByHour from './monthlySummaryByHour/LcMonthlySummaryByHour.vue'
import HdMonthlySummaryByHour from './monthlySummaryByHour/HdMonthlySummaryByHour.vue'
import TpMonthlySummaryByHour from './monthlySummaryByHour/TpMonthlySummaryByHour.vue'
import BtMonthlySummaryByHour from './monthlySummaryByHour/BtMonthlySummaryByHour.vue'
import LbMonthlySummaryByHour from './monthlySummaryByHour/LbMonthlySummaryByHour.vue'
import BdMonthlySummaryByHour from './monthlySummaryByHour/BdMonthlySummaryByHour.vue'
import TmMonthlySummaryByHour from './monthlySummaryByHour/TmMonthlySummaryByHour.vue'
import PpMonthlySummaryByHour from './monthlySummaryByHour/PpMonthlySummaryByHour.vue'

const userData = ref([])
const deliveryOption = ref('dailySummary')
const currentDate = new Date()
const USER_APP_STORE_MODULE_NAME = 'app-entranceSearch4'
const echarts = require('echarts')

moment.locale('en')

export default {
  components: {
    DateRangePicker,
    YearRangePicker,
    MonthRangePicker,
    Loading,
    LcMonthlySummaryByHour,
    HdMonthlySummaryByHour,
    TpMonthlySummaryByHour,
    BtMonthlySummaryByHour,
    LbMonthlySummaryByHour,
    BdMonthlySummaryByHour,
    TmMonthlySummaryByHour,
    PpMonthlySummaryByHour,
  },
  computed: {
    computedDateFormattedComparisonFromMonth() {
      return this.formatDate(this.comparisonFromMonth)
    },
    computedDateFormattedComparisonToMonth() {
      return this.formatDate(this.comparisonToMonth)
    },
    computedDateFormattedMsbyHourMonth() {
      return this.formatDate(this.msbyHourMonth)
    },
  },
  watch: {
    // userData を watch する
    userData(val) {
      setTimeout(() => {
        if (!isEmpty(val.seriesData)) {
          this.drawLine(val)
        }
        if (!isEmpty(val.pieData)) {
          this.drawPie(val)
        }
      }, 500)
    },
  },
  methods: {
    formatDate(date) {
      if (!date) return null

      return moment(date).format('MMM/yyyy')
    },
    countDisplay() {
      this.userData.labelShow = this.countDisplayswitch
      this.drawLine(this.userData)
    },
    search() {
      const isFormValid = this.$refs.form.validate()
      if (!isFormValid) return

      const siteId = String(getSessionData('data').siteId)
      let timeType = '1'
      let param = {}
      if (deliveryOption.value === 'dailySummary') {
        timeType = '1'
        const searchStartDate = date2String(this.$refs.dailyPicker.dateRange[0])
        const searchEndDate = date2String(this.$refs.dailyPicker.dateRange[1])
        param = {
          siteId,
          searchStartDate,
          searchEndDate,
          timeType,
        }
      } else if (deliveryOption.value === 'monthlySummary') {
        timeType = '2'

        const startDate = this.$refs.monthPicker.dateRange[0]
        const endDate = this.$refs.monthPicker.dateRange[1]
        console.log(startDate)
        const searchStartMonth = `${startDate.getFullYear()}
            -${(startDate.getMonth() + 1).toString().padStart(2, '0')}`.replace(/\s+/g, '')

        const searchEndMonth = `${endDate.getFullYear()}
            -${(endDate.getMonth() + 1).toString().padStart(2, '0')}`.replace(/\s+/g, '')
        param = {
          siteId,
          searchStartMonth,
          searchEndMonth,
          timeType,
        }
      } else if (deliveryOption.value === 'yearlySummary') {
        timeType = '3'
        const searchStartYear = this.$refs.yearPicker.dateRange[0].getFullYear()
        const searchEndYear = this.$refs.yearPicker.dateRange[1].getFullYear()
        param = {
          siteId,
          searchStartYear,
          searchEndYear,
          timeType,
        }
      } else if (deliveryOption.value === 'summaryDatabyHour') {
        timeType = '4'
        const searchStartDate = date2String(this.$refs.summaryDataPicker.dateRange[0])
        const searchEndDate = date2String(this.$refs.summaryDataPicker.dateRange[1])
        param = {
          siteId,
          searchStartDate,
          searchEndDate,
          timeType,
        }
      } else if (deliveryOption.value === 'comparisonSummaryDatabyHour') {
        timeType = '5'
        const searchMonth = this.comparisonFromMonth
        const searchComparisonMonth = this.comparisonToMonth
        param = {
          siteId,
          searchMonth,
          searchComparisonMonth,
          timeType,
        }
      } else if (deliveryOption.value === 'monthlySummarybyHour') {
        timeType = '6'
        const searchMonth = this.msbyHourMonth
        param = {
          siteId,
          searchMonth,
          timeType,
        }
      }
      this.loading = true
      store
        .dispatch('app-entranceSearch4/fetchData', {
          params: param,
        })
        .then(response => {
          userData.value = response.data.result.data
          userData.value.labelShow = this.countDisplayswitch
          this.loading = false

          if (deliveryOption.value !== 'monthlySummarybyHour') {
            let count = 0
            let count2 = 0
            userData.value.entranceSearch4Data.forEach(item => {
              count += Number(item.count)
              try {
                count2 += Number(item.count2)
              } catch (e) {
                console.log(e)
              }
            })
            this.tableFooterData.column1 = count
            this.tableFooterData.column2 = count2
          }
        })
        .catch(error => {
          console.log(error)
          this.$router.push({ name: 'error-login' })
        })
    },

    /* excelダウンロード */
    generateEntranceSearchXLSX: () => {
      const div = document.getElementById('mainTable')
      tableToExcelJs.convert(div, {
        name: `${date2yyyymmddhhmmss(new Date())}.xlsx`,
        sheet: {
          name: 'Data',
        },
      })
    },

    getClass(index) {
      let css = ''

      if (index % 2 !== 0) {
        css = 'Gray'
      }

      return css
    },

    getColor(index) {
      let color = ''
      if (index % 2 !== 0) {
        color = 'c0c0c0'
      }

      return color
    },

    // line chart作成する
    drawLine(val) {
      const newSeriesData = []

      for (let i = 0; i < Object.values(val.seriesData).length; i += 1) {
        const element = Object.values(val.seriesData)[i]

        const eleData = []
        for (let j = 0; j < element.data.length; j += 1) {
          if (j === Object.values(val.legendData).length) {
            break
          }
          eleData.push(element.data[j])
        }

        const newData = {
          name: element.name,
          type: deliveryOption.value === ('summaryDatabyHour' || 'summaryDatabyHour') ? 'bar' : 'line',
          label: {
            normal: {
              show: val.labelShow,
              formatter: params => {
                const v = parseFloat(params.value)

                return String(v).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')
              },
            },
          },
          color: ['#ffce9e', '#759aa0', '#e69d87', '#8dc1a9', '#ea7e53', '#eedd78', '#73a373', '#73b9bc', '#7289ab', '#91ca8c', '#f49f42'],
          data: eleData,
        }
        newSeriesData.push(newData)
      }

      const lineChartOption = {
        title: {
          left: '15%',
          text: val.text,
        },
        toolbox: {
          bottom: 0,
          left: 20,
          feature: {
            saveAsImage: { title: 'Save Image', name: `${date2yyyymmddhhmmss(new Date())}`, type: 'jpeg' },
          },
        },
        tooltip: { trigger: 'axis' },
        legend: {
          orient: 'vertical',
          top: '10%',
          left: '80%',
        },
        grid: {
          left: '8%',
          right: '28%',
          bottom: '3%',
          containLabel: true,
        },
        xAxis: {
          data: val.legendData,
        },
        yAxis: {},
        series: newSeriesData,
      }
      let lineChart = echarts.getInstanceByDom(this.$refs.lineChart)
      if (lineChart == null) {
        lineChart = echarts.init(this.$refs.lineChart)
      }

      lineChart.setOption(lineChartOption, true)
    },

    // pie chart作成する
    drawPie(val) {
      const percentageMap = new Map()
      let total = 0
      if (!isEmpty(val.pieData)) {
        val.pieData.forEach(element => {
          percentageMap.set(element.name, ((element.value / val.totalData) * 100).toFixed(1))
          total += element.value
        })
      }
      const pieChartOption = {
        toolbox: {
          bottom: 100,
          left: 20,
          feature: {
            saveAsImage: { title: 'Save Image', name: `${date2yyyymmddhhmmss(new Date())}`, type: 'jpeg' },
          },
        },
        tooltip: {
          trigger: 'item',
          formatter: params => {
            const v = parseFloat(params.value)
            const value = String(v).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')

            return `${params.name}: ${value} (${params.percent.toFixed(1)}%)`
          },
        },
        legend: {
          orient: 'vertical',
          type: 'scroll',
          right: 400,
          top: 50,
          bottom: 100,
          formatter: name => {
            const rate = percentageMap.get(name)

            return `${name} ${rate}%`
          },
        },
        color: ['#FFE3BF', '#D3ECA7', '#FFE162', '#C8F2EF', '#FFC0D3', '#8BDB81', '#BFFFF0', '#9AD0EC', '#F0ECE3', '#B5DEFF', '#D77FA1', '#F85F73'],
        series: [
          {
            // name: 'People Count',
            type: 'pie',
            radius: ['30%', '70%'],
            avoidLabelOverlap: false,
            center: ['30%', '50%'],
            label: {
              normal: {
                show: true,
                position: 'inner',
                textStyle: {
                  fontWeight: 300,
                  fontSize: 12,
                  color: 'black',
                },
                formatter: a => {
                  console.log(a)

                  return `${a.name}\n${a.percent.toFixed(1)}%`
                },
              },
            },
            labelLine: {
              show: false,
            },
            data: val.pieData,
          },
        ],
      }
      let pieChart = echarts.getInstanceByDom(this.$refs.pieChart)
      if (pieChart == null) {
        pieChart = echarts.init(this.$refs.pieChart)
      }

      pieChart.setOption(pieChartOption, true)
      if (total !== 0) {
        this.$refs.pieChart.style.display = 'block'
      } else {
        this.$refs.pieChart.style.display = 'none'
      }
    },
  },
  setup() {
    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) {
      store.registerModule(USER_APP_STORE_MODULE_NAME, entranceSearch4StoreModule)
    }

    onUnmounted(() => {
      try {
        userData.value.entranceSearch4Data = null
        userData.value.table1Data = null
        userData.value.table2Data = null
        userData.value.seriesData = null
        userData.value.pieData = null
      } catch (e) {
        console.log(e)
      }

      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    let startDateString = sessionStorage.getItem('startDate')
    let endDateString = sessionStorage.getItem('endDate')

    if (startDateString === '') {
      startDateString = currentDate
      endDateString = currentDate
    }

    const initDateRange = {
      startDate: startDateString,
      endDate: endDateString,
    }

    const date = ref(new Date().toISOString().substr(0, 7))
    const fromMonthMenu = ref(false)
    const toMonthMenu = ref(false)
    const comparisonFromMonthMenu = ref(false)
    const comparisonToMonthMenu = ref(false)
    const msbyHourMonthMenu = ref(false)
    const monthlySummaryErrorMessages = ref([])
    const yearlySummaryErrorMessages = ref([])

    const formatStringDate = varDate => {
      console.log(varDate)

      return moment(varDate).format('MMM/yyyy')
    }
    const countDisplayswitch = ref(true)
    const tableFooterData = {
      time: 'Total',
      column1: '',
      column2: '',
    }

    return {
      tableFooterData,
      countDisplayswitch,
      formatStringDate,
      userData,
      isEmpty,
      date2String,
      initDateRange,
      deliveryOption,
      date,
      fromMonthMenu,
      toMonthMenu,
      comparisonFromMonthMenu,
      comparisonToMonthMenu,
      msbyHourMonthMenu,

      // icons
      icons: {
        mdiDownloadOutline,
        mdiCalendarMonth,
      },
      DatePickerFormat: 'yyyy',
      activePicker: null,
      fromMonth: `${new Date().getFullYear()}-${(new Date().getMonth() + 1).toString().padStart(2, '0')}`,
      toMonth: `${new Date().getFullYear()}-${(new Date().getMonth() + 1).toString().padStart(2, '0')}`,
      comparisonFromMonth: `${new Date().getFullYear()}-${(new Date().getMonth() + 1).toString().padStart(2, '0')}`,
      comparisonToMonth: `${new Date().getFullYear()}-${(new Date().getMonth() + 1).toString().padStart(2, '0')}`,
      msbyHourMonth: `${new Date().getFullYear()}-${(new Date().getMonth() + 1).toString().padStart(2, '0')}`,
      menu: false,
      year: '',
      fromYear: `${new Date().getFullYear()}`,
      toYear: `${new Date().getFullYear()}`,
      loading: false,
      monthlySummaryErrorMessages,
      yearlySummaryErrorMessages,
      siteId: String(getSessionData('data').siteId),
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/preset/preset/mixins.scss';

@include theme--child(delivery-options) using ($material) {
  border: 1px solid map-deep-get($material, 'dividers');
  padding: 2rem;
  // &.active {
  //   border-color: var(--v-primary-base);
  // }
}
</style>
<style scoped>
/* .tableCss1 th,
td {
  width: 60px;
  white-space: nowrap;
  padding: 0px 5px !important;
  font-size: 12px !important;
  color: #000 !important;
}
.tableCss2 {
  width: 680px;
}
.tableCss2 th,
td {
  width: 60px;
  white-space: nowrap;
  padding: 0px 5px !important;
  font-size: 12px !important;
  color: #000 !important;
} */
.theme--light.v-data-table td {
  color: #000 !important;
  border: 0.5px solid #000;
  padding: 0px 5px !important;
  font-size: 12px !important;
  white-space: nowrap;
  width: 58px;
}
.theme--light.v-data-table th {
  border: 0.5px solid #000;
  padding: 0px 5px !important;
  white-space: nowrap;
  min-width: 58px;
}
.v-data-table tbody .Pink {
  background-color: rgb(255, 204, 255);
}

.v-data-table tbody .Gray {
  background-color: rgb(242, 242, 242);
}
</style>
