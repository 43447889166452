<template>
  <div>
      <v-col>
        <v-simple-table
          dense
          style="width:928px"
        >
          <template v-slot:default>
            <thead>
              <tr>
                <th
                  class="text-center"
                  rowspan="2"
                >
                  Time
                </th>
                <th
                  class="text-center"
                  style="background: #ffbbff !important"
                  colspan="9"
                >
                  GF
                </th>
                <th
                  class="text-center"
                  style="background: #FFC000 !important"
                >
                  1F
                </th>
                <th
                  class="text-center"
                  style="background: #ccffcc !important"
                >
                  2F
                </th>
                <th
                  class="text-center"
                  style="background: #64fcfc !important"
                  colspan="4"
                >
                  3F
                </th>
              </tr>
              <tr>
                <th class="text-center">
                  Ent A
                </th>
                <th class="text-center">
                  Ent B1
                </th>
                <th class="text-center">
                  Ent B2
                </th>
                <th class="text-center">
                  Ent C1
                </th>
                <th class="text-center">
                  Ent C2
                </th>
                <th class="text-center">
                  Ent D
                </th>
                <th class="text-center">
                  Ent K
                </th>
                <th class="text-center">
                  Ent L1
                </th>
                <th class="text-center">
                  Ent L2
                </th>
                <th class="text-center">
                  Ent P2
                </th>
                <th class="text-center">
                  Ent P4
                </th>
                <th class="text-center">
                  Ent H1
                </th>
                <th class="text-center">
                  Ent H2
                </th>
                <th class="text-center">
                  Ent H3
                </th>
                <th class="text-center">
                  Ent P6
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in userData.table1Data"
                :key="item.time"
                :class="getClass(index)"
              >
                <td
                  class="text-center"
                >
                  {{ item.time }}
                </td>
                <td class="text-right">
                  {{ item.count1 }}
                </td>
                <td class="text-right">
                  {{ item.count2 }}
                </td>
                <td class="text-right">
                  {{ item.count3 }}
                </td>
                <td class="text-right">
                  {{ item.count4 }}
                </td>
                <td class="text-right">
                  {{ item.count5 }}
                </td>
                <td class="text-right">
                  {{ item.count6 }}
                </td>
                <td class="text-right">
                  {{ item.count7 }}
                </td>
                <td class="text-right">
                  {{ item.count8 }}
                </td>
                <td class="text-right">
                  {{ item.count9 }}
                </td>
                <td class="text-right">
                  {{ item.count10 }}
                </td>
                <td class="text-right">
                  {{ item.count11 }}
                </td>
                <td class="text-right">
                  {{ item.count12 }}
                </td>
                <td class="text-right">
                  {{ item.count13 }}
                </td>
                <td class="text-right">
                  {{ item.count14 }}
                </td>
                <td class="text-right">
                  {{ item.count15 }}
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr
                v-for="item in userData.table1FooterData"
                :key="item.time"
                :class="item.time === 'Total' ? 'yellow': ''"
              >
                <td class="text-center">
                  {{ item.time }}
                </td>
                <td class="text-right">
                  {{ item.count1 }}
                </td>
                <td class="text-right">
                  {{ item.count2 }}
                </td>
                <td class="text-right">
                  {{ item.count3 }}
                </td>
                <td class="text-right">
                  {{ item.count4 }}
                </td>
                <td class="text-right">
                  {{ item.count5 }}
                </td>
                <td class="text-right">
                  {{ item.count6 }}
                </td>
                <td class="text-right">
                  {{ item.count7 }}
                </td>
                <td class="text-right">
                  {{ item.count8 }}
                </td>
                <td class="text-right">
                  {{ item.count9 }}
                </td>
                <td class="text-right">
                  {{ item.count10 }}
                </td>
                <td class="text-right">
                  {{ item.count11 }}
                </td>
                <td class="text-right">
                  {{ item.count12 }}
                </td>
                <td class="text-right">
                  {{ item.count13 }}
                </td>
                <td class="text-right">
                  {{ item.count14 }}
                </td>
                <td class="text-right">
                  {{ item.count15 }}
                </td>
              </tr>
            </tfoot>
          </template>
        </v-simple-table>
      </v-col>
      <br>
      <v-col>
        <v-simple-table
          dense
          style="width:754px"
        >
          <template v-slot:default>
            <thead>
              <tr>
                <th
                  class="text-center"
                  rowspan="2"
                >
                  Time
                </th>
                <th
                  class="text-center"
                  style="background: #ffff99 !important"
                  colspan="3"
                >
                  RF
                </th>
                <th
                  class="text-center"
                  style="background: #ccccff !important"
                  colspan="8"
                >
                  BF
                </th>
                <th
                  class="text-center"
                  style="background: #ffff88 !important;"
                  rowspan="2"
                >
                  Total
                </th>
              </tr>
              <tr>
                <th class="text-center">
                  Ent Q
                </th>
                <th class="text-center">
                  Ent R1
                </th>
                <th class="text-center">
                  Ent R2
                </th>
                <th class="text-center">
                  Ent E1
                </th>
                <th class="text-center">
                  Ent E2
                </th>
                <th class="text-center">
                  Ent F
                </th>
                <th class="text-center">
                  Ent G1
                </th>
                <th class="text-center">
                  Ent G2
                </th>
                <th class="text-center">
                  Ent M
                </th>
                <th class="text-center">
                  Ent N1
                </th>
                <th class="text-center">
                  Ent N2
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in userData.table2Data"
                :key="item.time"
                :class="getClass(index)"
              >
                <td class="text-center">
                  {{ item.time }}
                </td>
                <td class="text-right">
                  {{ item.count1 }}
                </td>
                <td class="text-right">
                  {{ item.count2 }}
                </td>
                <td class="text-right">
                  {{ item.count3 }}
                </td>
                <td class="text-right">
                  {{ item.count4 }}
                </td>
                <td class="text-right">
                  {{ item.count5 }}
                </td>
                <td class="text-right">
                  {{ item.count6 }}
                </td>
                <td class="text-right">
                  {{ item.count7 }}
                </td>
                <td class="text-right">
                  {{ item.count8 }}
                </td>
                <td class="text-right">
                  {{ item.count9 }}
                </td>
                <td class="text-right">
                  {{ item.count10 }}
                </td>
                <td class="text-right">
                  {{ item.count11 }}
                </td>
                <td class="text-right">
                  {{ item.count12 }}
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr
                v-for="item in userData.table2FooterData"
                :key="item.time"
                :class="item.time === 'Total' ? 'yellow': ''"
              >
                <td class="text-center">
                  {{ item.time }}
                </td>
                <td class="text-right">
                  {{ item.count1 }}
                </td>
                <td class="text-right">
                  {{ item.count2 }}
                </td>
                <td class="text-right">
                  {{ item.count3 }}
                </td>
                <td class="text-right">
                  {{ item.count4 }}
                </td>
                <td class="text-right">
                  {{ item.count5 }}
                </td>
                <td class="text-right">
                  {{ item.count6 }}
                </td>
                <td class="text-right">
                  {{ item.count7 }}
                </td>
                <td class="text-right">
                  {{ item.count8 }}
                </td>
                <td class="text-right">
                  {{ item.count9 }}
                </td>
                <td class="text-right">
                  {{ item.count10 }}
                </td>
                <td class="text-right">
                  {{ item.count11 }}
                </td>
                <td class="text-right">
                  {{ item.count12 }}
                </td>
              </tr>
            </tfoot>
          </template>
        </v-simple-table>
      </v-col>
    <br>
    <div
      v-if="!isEmpty(userData.table1Data)"
      id="mainTable2"
      hidden
      data-cols-width="20"
    >
      <v-col
        cols="12"
        md="12"
      >
        <table><tr></tr></table>
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
              <tr>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                >
                  Time
                </th>
                <th
                  data-b-a-s="thin"
                  data-a-h="center"
                  class="text-center"
                >
                  Ent A
                </th>
                <th
                  data-b-a-s="thin"
                  data-a-h="center"
                  class="text-center"
                >
                  Ent B1
                </th>
                <th
                  data-b-a-s="thin"
                  data-a-h="center"
                  class="text-center"
                >
                  Ent B2
                </th>
                <th
                  data-b-a-s="thin"
                  data-a-h="center"
                  class="text-center"
                >
                  Ent C1
                </th>
                <th
                  data-b-a-s="thin"
                  data-a-h="center"
                  class="text-center"
                >
                  Ent C2
                </th>
                <th
                  data-b-a-s="thin"
                  data-a-h="center"
                  class="text-center"
                >
                  Ent D
                </th>
                <th
                  data-b-a-s="thin"
                  data-a-h="center"
                  class="text-center"
                >
                  Ent K
                </th>
                <th
                  data-b-a-s="thin"
                  data-a-h="center"
                  class="text-center"
                >
                  Ent L1
                </th>
                <th
                  data-b-a-s="thin"
                  data-a-h="center"
                  class="text-center"
                >
                  Ent L2
                </th>
                <th
                  data-b-a-s="thin"
                  data-a-h="center"
                  class="text-center"
                >
                  Ent P2
                </th>
                <th
                  data-b-a-s="thin"
                  data-a-h="center"
                  class="text-center"
                >
                  Ent P4
                </th>
                <th
                  data-b-a-s="thin"
                  data-a-h="center"
                  class="text-center"
                >
                  Ent H1
                </th>
                <th
                  data-b-a-s="thin"
                  data-a-h="center"
                  class="text-center"
                >
                  Ent H2
                </th>
                <th
                  data-b-a-s="thin"
                  data-a-h="center"
                  class="text-center"
                >
                  Ent H3
                </th>
                <th
                  data-b-a-s="thin"
                  data-a-h="center"
                  class="text-center"
                >
                  Ent P6
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in userData.table1Data"
                :key="item.time"
              >
                <td
                  data-a-h="center"
                  data-b-a-s="thin"
                  :data-fill-color="getColor(index)"
                >
                  {{ item.time }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.count1 === '0' ? '0':'#,###'"
                  :data-fill-color="getColor(index)"
                >
                  {{ String(item.count1).replace(',','') }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.count2 === '0' ? '0':'#,###'"
                  :data-fill-color="getColor(index)"
                >
                  {{ String(item.count2).replace(',','') }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.count3 === '0' ? '0':'#,###'"
                  :data-fill-color="getColor(index)"
                >
                  {{ String(item.count3).replace(',','') }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.count4 === '0' ? '0':'#,###'"
                  :data-fill-color="getColor(index)"
                >
                  {{ String(item.count4).replace(',','') }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.count5 === '0' ? '0':'#,###'"
                  :data-fill-color="getColor(index)"
                >
                  {{ String(item.count5).replace(',','') }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.count6 === '0' ? '0':'#,###'"
                  :data-fill-color="getColor(index)"
                >
                  {{ String(item.count6).replace(',','') }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.count7 === '0' ? '0':'#,###'"
                  :data-fill-color="getColor(index)"
                >
                  {{ String(item.count7).replace(',','') }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.count8 === '0' ? '0':'#,###'"
                  :data-fill-color="getColor(index)"
                >
                  {{ String(item.count8).replace(',','') }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.count9 === '0' ? '0':'#,###'"
                  :data-fill-color="getColor(index)"
                >
                  {{ String(item.count9).replace(',','') }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.count10 === '0' ? '0':'#,###'"
                  :data-fill-color="getColor(index)"
                >
                  {{ String(item.count10).replace(',','') }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.count11 === '0' ? '0':'#,###'"
                  :data-fill-color="getColor(index)"
                >
                  {{ String(item.count11).replace(',','') }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.count12 === '0' ? '0':'#,###'"
                  :data-fill-color="getColor(index)"
                >
                  {{ String(item.count12).replace(',','') }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.count13 === '0' ? '0':'#,###'"
                  :data-fill-color="getColor(index)"
                >
                  {{ String(item.count13).replace(',','') }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.count14 === '0' ? '0':'#,###'"
                  :data-fill-color="getColor(index)"
                >
                  {{ String(item.count14).replace(',','') }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.count15 === '0' ? '0':'#,###'"
                  :data-fill-color="getColor(index)"
                >
                  {{ String(item.count15).replace(',','') }}
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr
                v-for="(item, index) in userData.table1FooterData"
                :key="item.time"
              >
                <td
                  data-b-a-s="thin"
                  data-a-h="center"
                  :data-fill-color="index == 0 ? 'ffff88':''"
                >
                  {{ item.time }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-fill-color="index == 0 ? 'ffff88':''"
                  :data-num-fmt="index == 0 ? (item.count1 === '0'?'0':'###,###'):'0.0%'"
                >
                  <p v-if="index == 0">
                    {{ String(item.count1).replace(',','') }}
                  </p>
                  <p v-if="index != 0">
                    {{ Number(String(item.count1).replace('%',''))/100 }}
                  </p>
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-fill-color="index == 0 ? 'ffff88':''"
                  :data-num-fmt="index == 0 ? (item.count2 === '0'?'0':'###,###'):'0.0%'"
                >
                  <p v-if="index == 0">
                    {{ String(item.count2).replace(',','') }}
                  </p>
                  <p v-if="index != 0">
                    {{ Number(String(item.count2).replace('%',''))/100 }}
                  </p>
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-fill-color="index == 0 ? 'ffff88':''"
                  :data-num-fmt="index == 0 ? (item.count3 === '0'?'0':'###,###'):'0.0%'"
                >
                  <p v-if="index == 0">
                    {{ String(item.count3).replace(',','') }}
                  </p>
                  <p v-if="index != 0">
                    {{ Number(String(item.count3).replace('%',''))/100 }}
                  </p>
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-fill-color="index == 0 ? 'ffff88':''"
                  :data-num-fmt="index == 0 ? (item.count4 === '0'?'0':'###,###'):'0.0%'"
                >
                  <p v-if="index == 0">
                    {{ String(item.count4).replace(',','') }}
                  </p>
                  <p v-if="index != 0">
                    {{ Number(String(item.count4).replace('%',''))/100 }}
                  </p>
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-fill-color="index == 0 ? 'ffff88':''"
                  :data-num-fmt="index == 0 ? (item.count5 === '0'?'0':'###,###'):'0.0%'"
                >
                  <p v-if="index == 0">
                    {{ String(item.count5).replace(',','') }}
                  </p>
                  <p v-if="index != 0">
                    {{ Number(String(item.count5).replace('%',''))/100 }}
                  </p>
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-fill-color="index == 0 ? 'ffff88':''"
                  :data-num-fmt="index == 0 ? (item.count6 === '0'?'0':'###,###'):'0.0%'"
                >
                  <p v-if="index == 0">
                    {{ String(item.count6).replace(',','') }}
                  </p>
                  <p v-if="index != 0">
                    {{ Number(String(item.count6).replace('%',''))/100 }}
                  </p>
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-fill-color="index == 0 ? 'ffff88':''"
                  :data-num-fmt="index == 0 ? (item.count7 === '0'?'0':'###,###'):'0.0%'"
                >
                  <p v-if="index == 0">
                    {{ String(item.count7).replace(',','') }}
                  </p>
                  <p v-if="index != 0">
                    {{ Number(String(item.count7).replace('%',''))/100 }}
                  </p>
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-fill-color="index == 0 ? 'ffff88':''"
                  :data-num-fmt="index == 0 ? (item.count8 === '0'?'0':'###,###'):'0.0%'"
                >
                  <p v-if="index == 0">
                    {{ String(item.count8).replace(',','') }}
                  </p>
                  <p v-if="index != 0">
                    {{ Number(String(item.count8).replace('%',''))/100 }}
                  </p>
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-fill-color="index == 0 ? 'ffff88':''"
                  :data-num-fmt="index == 0 ? (item.count9 === '0'?'0':'###,###'):'0.0%'"
                >
                  <p v-if="index == 0">
                    {{ String(item.count9).replace(',','') }}
                  </p>
                  <p v-if="index != 0">
                    {{ Number(String(item.count9).replace('%',''))/100 }}
                  </p>
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-fill-color="index == 0 ? 'ffff88':''"
                  :data-num-fmt="index == 0 ? (item.count10 === '0'?'0':'###,###'):'0.0%'"
                >
                  <p v-if="index == 0">
                    {{ String(item.count10).replace(',','') }}
                  </p>
                  <p v-if="index != 0">
                    {{ Number(String(item.count10).replace('%',''))/100 }}
                  </p>
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-fill-color="index == 0 ? 'ffff88':''"
                  :data-num-fmt="index == 0 ? (item.count11 === '0'?'0':'###,###'):'0.0%'"
                >
                  <p v-if="index == 0">
                    {{ String(item.count11).replace(',','') }}
                  </p>
                  <p v-if="index != 0">
                    {{ Number(String(item.count11).replace('%',''))/100 }}
                  </p>
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-fill-color="index == 0 ? 'ffff88':''"
                  :data-num-fmt="index == 0 ? (item.count12 === '0'?'0':'###,###'):'0.0%'"
                >
                  <p v-if="index == 0">
                    {{ String(item.count12).replace(',','') }}
                  </p>
                  <p v-if="index != 0">
                    {{ Number(String(item.count12).replace('%',''))/100 }}
                  </p>
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-fill-color="index == 0 ? 'ffff88':''"
                  :data-num-fmt="index == 0 ? (item.count13 === '0'?'0':'###,###'):'0.0%'"
                >
                  <p v-if="index == 0">
                    {{ String(item.count13).replace(',','') }}
                  </p>
                  <p v-if="index != 0">
                    {{ Number(String(item.count13).replace('%',''))/100 }}
                  </p>
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-fill-color="index == 0 ? 'ffff88':''"
                  :data-num-fmt="index == 0 ? (item.count14 === '0'?'0':'###,###'):'0.0%'"
                >
                  <p v-if="index == 0">
                    {{ String(item.count14).replace(',','') }}
                  </p>
                  <p v-if="index != 0">
                    {{ Number(String(item.count14).replace('%',''))/100 }}
                  </p>
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-fill-color="index == 0 ? 'ffff88':''"
                  :data-num-fmt="index == 0 ? (item.count15 === '0'?'0':'###,###'):'0.0%'"
                >
                  <p v-if="index == 0">
                    {{ String(item.count15).replace(',','') }}
                  </p>
                  <p v-if="index != 0">
                    {{ Number(String(item.count15).replace('%',''))/100 }}
                  </p>
                </td>
              </tr>
            </tfoot>
          </template>
        </v-simple-table>
      </v-col>
      <br>
      <v-col
        cols="12"
        md="9"
      >
        <table><tr></tr></table>
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
              <tr>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                >
                  Time
                </th>
                <th
                  data-b-a-s="thin"
                  data-a-h="center"
                  class="text-center"
                >
                  Ent Q
                </th>
                <th
                  data-b-a-s="thin"
                  data-a-h="center"
                  class="text-center"
                >
                  Ent R1
                </th>
                <th
                  data-b-a-s="thin"
                  data-a-h="center"
                  class="text-center"
                >
                  Ent R2
                </th>
                <th
                  data-b-a-s="thin"
                  data-a-h="center"
                  class="text-center"
                >
                  Ent E1
                </th>
                <th
                  data-b-a-s="thin"
                  data-a-h="center"
                  class="text-center"
                >
                  Ent E2
                </th>
                <th
                  data-b-a-s="thin"
                  data-a-h="center"
                  class="text-center"
                >
                  Ent F
                </th>
                <th
                  data-b-a-s="thin"
                  data-a-h="center"
                  class="text-center"
                >
                  Ent G1
                </th>
                <th
                  data-b-a-s="thin"
                  data-a-h="center"
                  class="text-center"
                >
                  Ent G2
                </th>
                <th
                  data-b-a-s="thin"
                  data-a-h="center"
                  class="text-center"
                >
                  Ent M
                </th>
                <th
                  data-b-a-s="thin"
                  data-a-h="center"
                  class="text-center"
                >
                  Ent N1
                </th>
                <th
                  data-b-a-s="thin"
                  data-a-h="center"
                  class="text-center"
                >
                  Ent N2
                </th>
                <th
                  data-a-h="center"
                  data-b-a-s="thin"
                  data-fill-color="ffff88"
                >
                  Total
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in userData.table2Data"
                :key="item.time"
              >
                <td
                  data-a-h="center"
                  data-b-a-s="thin"
                  :data-fill-color="getColor(index)"
                >
                  {{ item.time }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.count1 === '0' ? '0':'#,###'"
                  :data-fill-color="getColor(index)"
                >
                  {{ String(item.count1).replace(',','') }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.count2 === '0' ? '0':'#,###'"
                  :data-fill-color="getColor(index)"
                >
                  {{ String(item.count2).replace(',','') }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.count3 === '0' ? '0':'#,###'"
                  :data-fill-color="getColor(index)"
                >
                  {{ String(item.count3).replace(',','') }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.count4 === '0' ? '0':'#,###'"
                  :data-fill-color="getColor(index)"
                >
                  {{ String(item.count4).replace(',','') }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.count5 === '0' ? '0':'#,###'"
                  :data-fill-color="getColor(index)"
                >
                  {{ String(item.count5).replace(',','') }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.count6 === '0' ? '0':'#,###'"
                  :data-fill-color="getColor(index)"
                >
                  {{ String(item.count6).replace(',','') }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.count7 === '0' ? '0':'#,###'"
                  :data-fill-color="getColor(index)"
                >
                  {{ String(item.count7).replace(',','') }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.count8 === '0' ? '0':'#,###'"
                  :data-fill-color="getColor(index)"
                >
                  {{ String(item.count8).replace(',','') }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.count9 === '0' ? '0':'#,###'"
                  :data-fill-color="getColor(index)"
                >
                  {{ String(item.count9).replace(',','') }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.count10 === '0' ? '0':'#,###'"
                  :data-fill-color="getColor(index)"
                >
                  {{ String(item.count10).replace(',','') }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.count11 === '0' ? '0':'#,###'"
                  :data-fill-color="getColor(index)"
                >
                  {{ String(item.count11).replace(',','') }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-num-fmt="item.count12 === '0' ? '0':'#,###'"
                  :data-fill-color="getColor(index)"
                >
                  {{ String(item.count12).replace(',','') }}
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr
                v-for="(item, index) in userData.table2FooterData"
                :key="item.time"
              >
                <td
                  data-b-a-s="thin"
                  data-a-h="center"
                  :data-fill-color="index == 0 ? 'ffff88':''"
                >
                  {{ item.time }}
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-fill-color="index == 0 ? 'ffff88':''"
                  :data-num-fmt="index == 0 ? (item.count1 === '0'?'0':'###,###'):'0.0%'"
                >
                  <p v-if="index == 0">
                    {{ String(item.count1).replace(',','') }}
                  </p>
                  <p v-if="index != 0">
                    {{ Number(String(item.count1).replace('%',''))/100 }}
                  </p>
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-fill-color="index == 0 ? 'ffff88':''"
                  :data-num-fmt="index == 0 ? (item.count2 === '0'?'0':'###,###'):'0.0%'"
                >
                  <p v-if="index == 0">
                    {{ String(item.count2).replace(',','') }}
                  </p>
                  <p v-if="index != 0">
                    {{ Number(String(item.count2).replace('%',''))/100 }}
                  </p>
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-fill-color="index == 0 ? 'ffff88':''"
                  :data-num-fmt="index == 0 ? (item.count3 === '0'?'0':'###,###'):'0.0%'"
                >
                  <p v-if="index == 0">
                    {{ String(item.count3).replace(',','') }}
                  </p>
                  <p v-if="index != 0">
                    {{ Number(String(item.count3).replace('%',''))/100 }}
                  </p>
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-fill-color="index == 0 ? 'ffff88':''"
                  :data-num-fmt="index == 0 ? (item.count4 === '0'?'0':'###,###'):'0.0%'"
                >
                  <p v-if="index == 0">
                    {{ String(item.count4).replace(',','') }}
                  </p>
                  <p v-if="index != 0">
                    {{ Number(String(item.count4).replace('%',''))/100 }}
                  </p>
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-fill-color="index == 0 ? 'ffff88':''"
                  :data-num-fmt="index == 0 ? (item.count5 === '0'?'0':'###,###'):'0.0%'"
                >
                  <p v-if="index == 0">
                    {{ String(item.count5).replace(',','') }}
                  </p>
                  <p v-if="index != 0">
                    {{ Number(String(item.count5).replace('%',''))/100 }}
                  </p>
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-fill-color="index == 0 ? 'ffff88':''"
                  :data-num-fmt="index == 0 ? (item.count6 === '0'?'0':'###,###'):'0.0%'"
                >
                  <p v-if="index == 0">
                    {{ String(item.count6).replace(',','') }}
                  </p>
                  <p v-if="index != 0">
                    {{ Number(String(item.count6).replace('%',''))/100 }}
                  </p>
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-fill-color="index == 0 ? 'ffff88':''"
                  :data-num-fmt="index == 0 ? (item.count7 === '0'?'0':'###,###'):'0.0%'"
                >
                  <p v-if="index == 0">
                    {{ String(item.count7).replace(',','') }}
                  </p>
                  <p v-if="index != 0">
                    {{ Number(String(item.count7).replace('%',''))/100 }}
                  </p>
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-fill-color="index == 0 ? 'ffff88':''"
                  :data-num-fmt="index == 0 ? (item.count8 === '0'?'0':'###,###'):'0.0%'"
                >
                  <p v-if="index == 0">
                    {{ String(item.count8).replace(',','') }}
                  </p>
                  <p v-if="index != 0">
                    {{ Number(String(item.count8).replace('%',''))/100 }}
                  </p>
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-fill-color="index == 0 ? 'ffff88':''"
                  :data-num-fmt="index == 0 ? (item.count9 === '0'?'0':'###,###'):'0.0%'"
                >
                  <p v-if="index == 0">
                    {{ String(item.count9).replace(',','') }}
                  </p>
                  <p v-if="index != 0">
                    {{ Number(String(item.count9).replace('%',''))/100 }}
                  </p>
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-fill-color="index == 0 ? 'ffff88':''"
                  :data-num-fmt="index == 0 ? (item.count10 === '0'?'0':'###,###'):'0.0%'"
                >
                  <p v-if="index == 0">
                    {{ String(item.count10).replace(',','') }}
                  </p>
                  <p v-if="index != 0">
                    {{ Number(String(item.count10).replace('%',''))/100 }}
                  </p>
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-fill-color="index == 0 ? 'ffff88':''"
                  :data-num-fmt="index == 0 ? (item.count11 === '0'?'0':'###,###'):'0.0%'"
                >
                  <p v-if="index == 0">
                    {{ String(item.count11).replace(',','') }}
                  </p>
                  <p v-if="index != 0">
                    {{ Number(String(item.count11).replace('%',''))/100 }}
                  </p>
                </td>
                <td
                  data-b-a-s="thin"
                  data-t="n"
                  :data-fill-color="index == 0 ? 'ffff88':''"
                  :data-num-fmt="index == 0 ? (item.count12 === '0'?'0':'###,###'):'0.0%'"
                >
                  <p v-if="index == 0">
                    {{ String(item.count12).replace(',','') }}
                  </p>
                  <p v-if="index != 0">
                    {{ Number(String(item.count12).replace('%',''))/100 }}
                  </p>
                </td>
              </tr>
            </tfoot>
          </template>
        </v-simple-table>
      </v-col>
    </div>
    <v-btn
      v-if="!isEmpty(userData)"
      color="primary"
      dark
      style="text-transform: none"
      @click="generateXLSX"
    >
      Excel Download
      <v-icon
        dark
        right
      >
        {{ icons.mdiDownloadOutline }}
      </v-icon>
    </v-btn>
  </div>
</template>

<script>
import { isEmpty, date2yyyymmddhhmmss } from '@core/utils'
import { mdiDownloadOutline } from '@mdi/js'
import tableToExcelJs from '@linways/table-to-excel'

export default {
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  methods: {
    /* excelダウンロード */
    generateXLSX: () => {
      const div = document.getElementById('mainTable2')
      tableToExcelJs.convert(div, {
        name: `${date2yyyymmddhhmmss(new Date())}.xlsx`,
        sheet: {
          name: 'Data',
        },
      })
    },

    getClass(index) {
      let css = ''

      if (index % 2 !== 0) {
        css = 'Gray'
      }

      return css
    },

    getColor(index) {
      let color = ''
      if (index % 2 !== 0) {
        color = 'c0c0c0'
      }

      return color
    },
  },
  setup() {
    return {
      // icons
      icons: {
        mdiDownloadOutline,
      },
      isEmpty,
    }
  },
}
</script>

<style scoped>
.theme--light.v-data-table td {
  color: #000 !important;
  border: 0.5px solid #000;
  padding: 0px 1px !important;
  font-size: 12px !important;
  white-space: nowrap;
  width: 58px;
}
.theme--light.v-data-table th {
  border: 0.5px solid #000;
  padding: 0px 5px !important;
  white-space: nowrap;
  min-width: 58px;
  height: 14px;
}
.v-data-table tbody .Pink {
  background-color: rgb(255, 204, 255);
}

.v-data-table tbody .Gray {
  background-color: rgb(242, 242, 242);
}
</style>
